import React from 'react';
import CategoryTag from 'components/CategoryTag/CategoryTag';
import styled from 'styled-components';

const Wrapper = styled.div` 
  cursor: pointer;
  margin: -3px 0 0;
`

const Select = styled.select` 
  padding: 3px 10px;
  border-radius: 2px;
  color: rgb(var(--theme-primary));
  background: rgb(var(--theme-secondary));
  border: none;
  -webkit-appearance: none;
  -moz-appearance: none;

  &:after {
    content: '↕';
    display: inline-block;
    width: 20px;
    height: 100%;
  }
`

const Sort = ({children, ...rest}) => {
  return (
    <Wrapper>
      <Select {...rest}>
        { children }
      </Select>
    </Wrapper>
  )
}

export default Sort;