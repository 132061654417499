import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { fontstack } from 'utils/fontstack';
import { type } from 'utils/type';

const Wrapper = styled.div`
  ${fontstack.default}
  ${type('body')}
  position: relative;
  color: rgb(var(--theme-primary));
  opacity: ${props => props.active && props.sortable ? '1' : '0.4'};
  display: flex;
  cursor: ${props => props.sortable ? 'pointer' : 'default'};
`

const Icon = styled.span` 
  margin: 0 0 0 4px;
  &:after {
    content: '${props => props.sortable ? '↓' : ''}';
    display: inline-block;
    transform: ${props => props.direction === 'ASC' ? 'rotate(180deg)' : 'rotate(0deg)'};
  }
`

const IndexColumnLabel = ({children, active, setActive, setFilters, sortable, reset}) => {
  const [ direction, setDirection ] = useState('DESC');

  useEffect(()=>{
    if(!active) {
      setDirection('DESC')
    } else if(active && reset) {
      console.log('reset');
      setDirection('DESC')
    }
  }, [active,reset])

  const toggleDirection = () => {
    if(active && sortable) {
      if(direction === 'DESC') {
        setDirection('ASC');
        setFilters('ASC');
      } else {
        setDirection('DESC');
        setFilters('DESC');
      }
    } else if(sortable){
      setActive();
      setDirection('ASC');
      setFilters('ASC');
    }
  }

  return (
    <Wrapper active={active} onClick={toggleDirection} sortable={sortable}>
      {children}
      <Icon active={active} direction={direction} sortable={sortable}/>
    </Wrapper>
  )
}

export default IndexColumnLabel;