import React, { useState, useEffect, useRef } from "react"
import { gsap } from "gsap";
import { LazyBlur } from '@manualengineering/react-lazyblur';
import styled from 'styled-components';
import Section from "components/Section/Section";
import Layout from "components/Layout/Layout"
import Seo from "components/seo"
import { Link } from "gatsby";
import IndexLine, { Headline } from 'components/Index/IndexLine';
import { getValuesFromQuery } from "utils/filter";
import useAlbumFilter from "utils/useAlbumFilter";



const IndexLayout = styled(Layout)`
  background: rgb(var(--theme-background));
  overflow: visible;
`

const IndexSection = styled(Section)`
`

const IndexWrapper = styled.div` 
  position: relative;
  margin: 160px 0 120px;
`

const StageWrapper = styled.div` 
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  pointer-events: none;
  opacity: ${props => props.active ? '1' : '0'};
`

const StageImageWrapper = styled.div`
  position: absolute;
  width: 350px;
`
const StageImage = styled.img` 
  position: relative;
  width: 100%;
  background: grey;
`

export async function getServerData({ query, ...rest }) {
  const { getFilterResults } = require("../../utils/filter")
  const { albums, tags } = await getFilterResults({
    query
  })

  return {
    props: {
      query,
      albums,
      tags
    },
  }
}


const ListPage = (props) => {
  const { serverData } = props;
  const StageRef = useRef();
  const ThumbRef = useRef();
  const ScrollPos = useRef();
  const [ ThumbImage, setThumbImage ] = useState();
  const [ Active, setActive ] = useState(null);
  const [ StageActive, setStageActive ] = useState();

  useEffect(() => {
    window.addEventListener('scroll', listenToScroll);
    window.addEventListener('resize', listenToScroll);
    listenToScroll();

    return () => {
      window.removeEventListener('scroll', listenToScroll);
      window.removeEventListener('resize', listenToScroll);
      
    }

  },[])
  // These default values come from the page query string
  const queryParams = getValuesFromQuery(serverData.query)
  const [filters, setFilters] = React.useState(queryParams)
  const initialFilters = React.useMemo(() => queryParams, [])
  
  const {
    albums,
  } = useAlbumFilter(
    filters,
    serverData.albums,
    false,
    serverData.tags,
    initialFilters
    )
  const reducedAlbums = albums && albums.reduce((acc,curr)=>{
    const publishDate = new Date(curr._meta.firstPublicationDate);
    acc.push({
      uid: curr._meta.uid,
      id: `${publishDate.getMonth()}${publishDate.getDate()}${publishDate.getFullYear()}${publishDate.getHours()}${publishDate.getMinutes()}`,
      artist: curr.artist,
      project: curr.project,
      year: curr.year,
      types: curr.types.length > 0 ? curr.types : null,
      formats: curr.formats.length > 0 ? curr.formats : null
    })

    return acc;
  },[])

  const listenToScroll = () => {
    ScrollPos.current = StageRef.current.getBoundingClientRect();
  }

  const onMouseEnter = (index) => {
    setActive(index);
    const node = albums[index];
    if(node.carousel[0].image) {
      setThumbImage({
        album:albums[index].carousel[0].image,
        index: index
      })
    } else if(node.video_thumb) {
      setThumbImage({
        album: node.video_thumb,
        index: index 
      })
    }
  }
  
  const onMouseLeave = () => {
    setActive(null);
    setThumbImage(null);
  }

  const removeParams = (source) => {
    const url = new URL(source);
    return  url.origin + url.pathname;
  }

  const onStageMouseMove = (e) => {
    const width = ThumbRef.current.offsetWidth;
    const height = ThumbRef.current.offsetHeight;
    const x = e.clientX - ScrollPos.current.left - (width/2);
    const y = e.clientY - ScrollPos.current.top - (height/2);


    gsap.set(ThumbRef.current, 
      {
        y: y,
        x: x
      }
    );
  }

  return <IndexLayout disableOverflowHidden setFilters={setFilters} filters={filters} activeTag="index">
    <Seo title="Index" />
    <IndexSection>
      <IndexWrapper ref={StageRef} onMouseMove={onStageMouseMove} onMouseOver={()=>setStageActive(true)} onMouseLeave={()=>setStageActive(false)}>
        <Headline setFilters={setFilters} filters={filters}/>
        { reducedAlbums && reducedAlbums.map((line,index) => {
          let disabled;
          if(Active !== null) {
            if(Active !== index) {
              disabled = true;
            }
          } else {
            disabled = false;
          }
          return <Link to={`/${line.uid}`}><IndexLine key={index} disabled={disabled} data={line} onMouseEnter={()=>onMouseEnter(index)} onMouseLeave={onMouseLeave}/></Link>
        }) }
        <StageWrapper active={StageActive}>
          <StageImageWrapper ref={ThumbRef}>
          { ThumbImage ? <StageImage src={`${ThumbImage.album.url}&w=700&q=70`}/> : null }
          </StageImageWrapper>
        </StageWrapper>
      </IndexWrapper>
    </IndexSection>
  </IndexLayout>
}

export default ListPage
