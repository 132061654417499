import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import IndexColumnLabel from './IndexColumnLabel';
import Sort from 'components/Sort/Sort';
import { fontstack } from 'utils/fontstack';
import { type } from 'utils/type';
import { media } from "utils/mediaQuery";

const Wrapper = styled.div`
  position: relative;
  padding: 16px 0;
  display: grid;
  column-gap: 16px;
  width: 100%;
  opacity: ${props => props.disabled ? '0.4' : '1'};
  z-index: ${props => props.disabled ? '0' : '2'};
`

const Col = styled.div` 
  ${fontstack.default}
  ${type('body')}
  color: rgb(var(--theme-primary));
`

const IdCol = styled(Col)`
  grid-column: 1 / span 2;
`

const ArtistCol = styled(Col)`
  grid-column: 3 / span 4;
`

const ProjectCol = styled(Col)`
  grid-column: 7 / span 8;
`

const TypeCol = styled(Col)`
  grid-column: 15 / span 4;
`

const FormatCol = styled(Col)`
  grid-column: 19 / span 4;
`
const YearCol = styled(Col)`
  grid-column: 23  / span 2;
`

const DesktopWrapper = styled(Wrapper)` 
  grid-template-columns: repeat(24,1fr);
  display: none;

  ${media.large`
    display: grid;
  `}
`

const MediumWrapper = styled(Wrapper)`
  grid-template-columns: repeat(3,1fr);
  row-gap: 4px;
  display: none;

  ${media.small`
    display: grid;
  `}

  ${media.large`
    display: none;
  `}
`

const MobileWrapper = styled(Wrapper)`
  grid-template-columns: repeat(2,1fr);
  row-gap: 4px;
  ${media.small`
    display: none;
  `}
`

const MobileLine = styled.div`
  display: flex;
  opacity: ${props => props.grey ? '0.4' : '1'};
`

const MobileLabel = styled.div` 
  margin: 0 10px 0 0;
  opacity: ${props => props.grey ? '0.4' : '1'};
`

const IndexLine = ({data, disabled, ...rest}) => {
  const {
    id,
    artist,
    project,
    type,
    format,
    types,
    formats,
    year
  } = data;

  const typesString =  types && types.length > 0 ? types.map((object,index) => { 
    if(object.type) {
      const tagstring = `${object.type.label}${(index !== types.length-1) ? ', ' : ''}`
      return tagstring;
    } 
    return '-';
    }) : type ? type : '-';

  const formatsString = formats && formats.length > 0 ? formats.map((object,index) => { 
    if(object.format) {
      const tagstring = `${object.format.label}${(index !== formats.length-1) ? ', ' : ''}`
      return tagstring;
    } 
    return '-';
    }) : format ? format : '-';

  return (
    <>
      <MobileWrapper>
        <Col>
          <MobileLine>{artist}</MobileLine>
        </Col>
        <Col>
          <MobileLine>{project}</MobileLine>
        </Col>
        <Col>
          <MobileLine>{id}</MobileLine>
        </Col>
        <Col>
          <MobileLine grey>{typesString}, {formatsString}, {year}</MobileLine>
        </Col>
      </MobileWrapper>
      <MediumWrapper>
        <Col>
          <MobileLine>{artist}</MobileLine>
        </Col>
        <Col>
          <MobileLine>{project}</MobileLine>
        </Col>
        <Col>
          <MobileLine grey>{typesString}, {formatsString}, {year}</MobileLine>
        </Col>
        <Col>
          <MobileLine>{id}</MobileLine>
        </Col>
      </MediumWrapper>
      <DesktopWrapper disabled={disabled} {...rest}>
        <IdCol>{id}</IdCol>
        <ArtistCol>{artist}</ArtistCol>
        <ProjectCol>{project}</ProjectCol>
        <TypeCol>{ typesString }</TypeCol>
        <FormatCol>{ formatsString }</FormatCol>
        <YearCol>{year}</YearCol>
      </DesktopWrapper>
    </>
    
  )
}

export default IndexLine;


const Headline = ({setFilters, filters}) => {
  const [ Active, setActive ] = useState('id');
  const [ Reset, setReset ] = useState(null);

  useEffect(()=> {
    if(!filters || !filters.sortBy ) {
      setActive('id');
      setReset('id');
    }
  },[filters])
  const updateFilters = (key,value) => {
    setReset(null);
    setFilters((filters) =>  ({...filters, [key]: value }))
  }

  const onMobileSortChange = (event) => {
    const option = event.target.value;

    const filters = {
      'id': () => {
        updateFilters('sortBy', `meta_firstPublicationDate_DESC`)
      },
      'artist': () => {
        updateFilters('sortBy', `artist_ASC`)
      },
      'project': () => {
        updateFilters('sortBy', `project_ASC`)
      },
    }
    
    filters[option]();
  }

  return (
    <>
    <MobileWrapper>
        <Col>
          <MobileLine grey>Artist, Index</MobileLine>
        </Col>
        <Col>
          <MobileLine><MobileLabel grey>Sort By</MobileLabel><Sort onChange={onMobileSortChange}>
        <option value="id">ID ↕</option>
        <option value="artist">Artist ↕</option>
        <option value="project">Project ↕</option>  
      </Sort></MobileLine>
        </Col>
      </MobileWrapper>
    <MediumWrapper>
        <Col>
          <MobileLine grey>Artist, Index</MobileLine>
        </Col>
        <Col>
          <MobileLine grey>Project</MobileLine>
        </Col>
        <Col>
          <MobileLine><MobileLabel grey>Sort By</MobileLabel><Sort onChange={onMobileSortChange}>
          <option value="id">ID</option>
        <option value="artist">Artist</option>
        <option value="project">Project</option>   
      </Sort></MobileLine>
        </Col>
      </MediumWrapper>
      <DesktopWrapper>
        <IdCol>
          <IndexColumnLabel 
            active={Active === 'id' || false} 
            reset={Reset === 'id' || false} 
            setActive={()=>setActive('id')} 
            setFilters={(direction)=>updateFilters('sortBy', `meta_firstPublicationDate_${direction}`)}
            sortable>
              ID
          </IndexColumnLabel>
        </IdCol>
        <ArtistCol>
          <IndexColumnLabel 
            active={Active === 'artist' || false}
            reset={Reset === 'artist' || false} 
            setActive={()=>setActive('artist')}
            setFilters={(direction)=>updateFilters('sortBy', `artist_${direction}`)} 
            sortable>
              Artist
          </IndexColumnLabel>
        </ArtistCol>
        <ProjectCol>
          <IndexColumnLabel 
            active={Active === 'project' || false}
            reset={Reset === 'project' || false} 
            setActive={()=>setActive('project')}
            setFilters={(direction)=>updateFilters('sortBy', `project_${direction}`)} 
            sortable>
              Project
          </IndexColumnLabel>
        </ProjectCol>
        <TypeCol>
          <IndexColumnLabel>
            Type
          </IndexColumnLabel>
        </TypeCol>
        <FormatCol>
          <IndexColumnLabel>
            Format
          </IndexColumnLabel>
        </FormatCol>
        <YearCol>
          <IndexColumnLabel 
            active={Active === 'year' || false}
            reset={Reset === 'year' || false}
            setActive={()=>setActive('year')}
            setFilters={(direction)=>updateFilters('sortBy', `year_${direction}`)} 
            sortable>
              Year
          </IndexColumnLabel>
        </YearCol>
      </DesktopWrapper>
    </>
  )
}

export { Headline };